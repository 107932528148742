<script setup>
  import { ref, computed } from 'vue';
  import { Link, usePage } from '@inertiajs/vue3';
  import MainButton from '@/Components/MainButton.vue';
  import SecondaryButton from '@/Components/SecondaryButton.vue';
  import LogoSVG from '@/Components/LogoSVG.vue';
  import LogoSVGWhite from '../LogoSVGWhite.vue';
  import NavbarToggleButton from '@/Components/Navs/NavbarToggleButton.vue';
  import DropdownComponent from '../DropdownComponent.vue';
  import DropdownItem from '../DropdownItem.vue';
  import PlansLogo from '@/Components/PlansLogo.vue';
  import { menuItems } from '@/constants/menuItems';

  const isMobileNavOpen = ref(false);

  const isLoggedIn = ref(false);
  // Access Inertia's page props
  const { url } = usePage();

  const textColorClass = computed(() => {
    if (
      url !== '/welcome-page' &&
      url !== '/welcome-page#collaborate' &&
      url !== '/' &&
      url !== '/about' &&
      url !== '/visitor-profiles-page' &&
      url !== '/visitor-gigs-page' &&
      url !== '/visitor-gig-detail' &&
      !url.startsWith('/visitor-profile-detail/') &&
      !url.startsWith('/s/profile/') &&
      !url.startsWith('/s/gig/') &&
      !url.startsWith('/visitor-gig-detail/')
    ) {
      return '!text-black';
    } else {
      return '!text-white hover:!text-white border-white';
    }
  });

  const currentLogoComponent = computed(() => {
    if (
      url === '/welcome-page' ||
      url === '/welcome-page#collaborate' ||
      url === '/' ||
      url === '/about' ||
      url === '/visitor-profiles-page' ||
      url === '/visitor-gigs-page' ||
      url === '/visitor-gig-detail' ||
      url.startsWith('/visitor-profile-detail/') ||
      url.startsWith('/s/profile/') ||
      url.startsWith('/s/gig/') ||
      url.startsWith('/visitor-gig-detail/')
    ) {
      return LogoSVGWhite;
    } else if (url === '/plans') {
      return PlansLogo;
    } else {
      return LogoSVG;
    }
  });

  const logoClass = computed(() => {
    if (
      url === '/welcome-page' ||
      url === '/welcome-page#collaborate' ||
      url === '/' ||
      url === '/about' ||
      url === '/visitor-profiles-page' ||
      url === '/visitor-gigs-page' ||
      url === '/visitor-gig-detail' ||
      url.startsWith('/visitor-profile-detail/') ||
      url.startsWith('/s/profile/') ||
      url.startsWith('/s/gig/') ||
      url.startsWith('/visitor-gig-detail/')
    ) {
      return 'dark:!text-white';
    } else {
      return '!text-black';
    }
  });

  const navLinksForVisitors = [
    { text: 'About Us', href: '/about' },
    { text: 'Profiles', href: '/visitor-profiles-page' },
    { text: 'Gigs', href: '/visitor-gigs-page' },
    { text: 'Messaging', href: '/register?p=messaging' }
    // { text: 'Registration', href: '/waitlist' }
    // { text: 'Login', href: '/login' }
  ];
  // TODO: ADD REAL LINKS:
  // const navLinksForUsers = [
  //   { text: 'Gigs Hub', href: '/user/gigs', disabled: !isLoggedIn.value },
  //   { text: 'All gigs', href: '/gigs', disabled: !isLoggedIn.value },
  //   {
  //     text: 'Create Profile',
  //     href: '/show-profile',
  //     disabled: !isLoggedIn.value
  //   },
  //   { text: 'Manage Calendar', href: '/#', disabled: !isLoggedIn.value },
  //   { text: 'Events', href: '/#', disabled: !isLoggedIn.value }
  // ];
  console.log('Logo Class:', logoClass.value);
</script>

<template>
  <div
    class="absolute z-20 flex h-20 w-full max-w-6xl flex-row items-center justify-between px-2"
    style="left: 50%; transform: translateX(-50%); z-index: 99999"
  >
    <div class="-ml-20">
      <a :href="'/'" class="pt-1">
        <component :is="currentLogoComponent" class="ml-20" />
        <!-- <LogoSVG :class="[logoClass]" /> -->
      </a>
    </div>

    <div
      class="hidden w-1/3 flex-row justify-around space-x-4 text-base md:flex"
    >
      <Link :href="'/'" class="hover:underline" :class="textColorClass">
        Home
      </Link>
      <Link :href="'/about'" class="hover:underline" :class="textColorClass">
        About Us
      </Link>
      <DropdownComponent :top="'30px'" :left="'-50px'" :class="textColorClass">
        <template v-slot:button>Visitors</template>
        <template v-slot:content>
          <div v-for="(link, index) in navLinksForVisitors" :key="index">
            <DropdownItem
              :href="link.href"
              class="!text-gray-200 hover:!text-white hover:underline"
            >
              {{ link.text }}
            </DropdownItem>
          </div>
          <DropdownItem
            class="!text-gray-200 hover:!text-white hover:underline"
            href="/register"
          >
            Register
          </DropdownItem>
          <DropdownItem
            class="!text-gray-200 hover:!text-white hover:underline"
            href="/login"
          >
            Login
          </DropdownItem>
        </template>
      </DropdownComponent>
      <DropdownComponent :top="'30px'" :left="'-50px'" :class="textColorClass">
        <template v-slot:button>Users</template>
        <template v-slot:content>
          <div v-for="(item, index) in menuItems" :key="index">
            <template v-if="isLoggedIn && !item.disabled">
              <Link
                :href="item.href"
                class="block !text-white hover:!text-white hover:underline"
              >
                {{ item.text }}
              </Link>
            </template>
            <template v-else>
              <span
                class="block cursor-not-allowed !text-white hover:!text-gray-500"
                :aria-disabled="true"
              >
                {{ item.text }}
              </span>
            </template>
          </div>
        </template>
      </DropdownComponent>
    </div>

    <NavbarToggleButton
      @click="isMobileNavOpen = !isMobileNavOpen"
      class="right-0 justify-end text-primary hover:text-primary-darker md:hidden"
    />

    <div class="hidden flex-row space-x-2 md:flex">
      <Link :href="'/login'">
        <SecondaryButton :class="textColorClass" class="!px-3 !py-1">
          Login
        </SecondaryButton>
      </Link>
      <Link :href="'/register'">
        <MainButton class="!px-3 !py-1">Sign Up</MainButton>
      </Link>
    </div>
  </div>

  <transition name="slide">
    <div
      v-if="isMobileNavOpen"
      class="fixed right-0 top-0 z-50 h-full w-full bg-[#1f0328] p-4 opacity-95 md:w-1/2"
      style="z-index: 99999"
    >
      <button
        @click="isMobileNavOpen = false"
        class="absolute right-4 top-4 text-2xl text-white"
      >
        <i class="fa-regular fa-circle-xmark text-gray-400"></i>
      </button>
      <div class="mt-20 flex flex-col items-center gap-4 text-white">
        <Link
          :href="'/home'"
          class="!text-white hover:!text-white hover:underline"
        >
          Home
        </Link>
        <div v-for="(link, index) in navLinksForVisitors" :key="index">
          <Link
            :href="link.href"
            class="!text-white hover:!text-white hover:underline"
          >
            {{ link.text }}
          </Link>
        </div>

        <Link :href="'/login'">
          <SecondaryButton class="border-white text-white">
            Login
          </SecondaryButton>
        </Link>
        <Link :href="'/register'">
          <MainButton>Sign Up</MainButton>
        </Link>
      </div>
    </div>
  </transition>
</template>

<style scoped>
  /* Your anchor tag styles */
  /* a {
    color: white !important;
  } */

  /* Mobile Navbar Button */
  .mobile-nav-button {
    @apply p-4;
  }

  /* Slide-in and Slide-out Animations */
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  .slide-enter-from {
    transform: translateX(100%);
  }

  .slide-enter-to {
    transform: translateX(0);
  }

  .slide-leave-from {
    transform: translateX(0);
  }

  .slide-leave-to {
    transform: translateX(100%);
  }
</style>
